const showEmailBtn = document.getElementById('show-email');
const showPhoneButton = document.getElementById('show-phone');
const emailText = document.getElementById('email');
const phoneText = document.getElementById('phone');

showEmailBtn.addEventListener('click', () => {
    showEmailBtn.style.display = 'none';
    getData('email')
        .then(email => {
            emailText.innerText = email;
        }).catch(error => {
        emailText.innerText = error;
        emailText.style.color = 'red';
        showEmailBtn.style.display = 'unset';
    })
})

showPhoneButton.addEventListener('click', () => {
    showPhoneButton.style.display = 'none';
    getData('phone')
        .then(phone => {
            phoneText.innerText = phone;
        }).catch(error => {
        phoneText.innerText = error;
        phoneText.style.color = 'red';
        showPhoneButton.style.display = 'unset';
    })
})

function getData(dataType) {
    return new Promise(function (resolve, reject) {
        grecaptcha.ready(() => {
            grecaptcha.execute('6LfQxCcaAAAAAIZDBw1ZT-zXVHst9fzUzHsbLSFb', {action: 'get/' + dataType}).then(token => {
                fetch('/data', {
                    method: 'POST',
                    body: (() => {
                        const data = new FormData;
                        data.set('token', token);
                        data.set('type', dataType);
                        return data;
                    })()
                })
                    .then(body => body.json())
                    .then(data => {
                        if (typeof data.fail === 'string') {
                            reject(data.fail);
                            return;
                        }
                        resolve(data.data);
                    })
            });
        });
    })
    //     fetch('/data', {
    //         method: 'POST',
    //         body: (() => {
    //             const data = new FormData;
    //             data.set('type', dataType);
    //             return data;
    //         })()
    //     })
    //         .then(body => body.json())
    //         .then(data => {
    //             if (typeof data.fail === 'string') {
    //                 reject(data.fail);
    //                 return;
    //             }
    //             resolve(data.data);
    //         })
    // })
}

